body {
  margin: 0;
  font-family: Rubik ,Roboto ,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to right, #243b55, #182539);
  color: #ffffff;
  overflow-x: hidden;
  letter-spacing: 1px;
  font-size: 3rem;
  padding-top: 0;
}
.row{
  padding-left: 0;
  padding-right: 0;
}
.col{
  padding-left: 0;
  padding-right: 0;
}
.grid{
  padding-left: 0;
  padding-right: 0;
}
.container {
  max-width: 100%
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*::-webkit-scrollbar{
  width: 0px;
}
*::-webkit-scrollbar:hover{
  width: 0px;
}
*::-webkit-scrollbar-track {
  background: rgba(31, 32, 28, 0);        /* color of the tracking area #1f2028 */
}

*::-webkit-scrollbar-thumb {
  background-color: #294171;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
}
p{
  line-height: 15px;

}
.btn{
  letter-spacing: 1px;
}
